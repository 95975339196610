import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { FormatDate } from 'src/app/util/format-date';

import Swal from 'sweetalert2';
import { IItens, IPedido } from '../../models/IPedidos';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { ListaPedidosFornecedorService } from './service/lista-pedidos-fornecedor.service';

declare let $: any;

type IDatasPesq = {
  dataDe: string;
  dataAte: string;
}

type IEmpresas = {
  id_cliente: string;
  id_empresa: string;
  id_loja_empresa: string;
  descricao: string;
}

type IStatus = {
  id: string;
  descricao: string;
}

type IFiltros = {
  empresas: string[];
  vendedor: string;
  status: string;
  dataDe: string;
  dataAte: string;
  order: string;
  orderKey: string;
}

@Component({
  selector: 'app-lista-pedidos-fornecedor',
  templateUrl: './lista-pedidos-fornecedor.component.html',
  styleUrls: ['./lista-pedidos-fornecedor.component.css']
})
export class ListaPedidosFornecedorComponent {

  Toast: any = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  dataInicial: string;
  dataFinal: string;
  datasAtuais: IDatasPesq;
  pesquisa: string;
  statusSelecionado: string = 'T';
  origemSelecionado: string = 'G';
  ativaLoading: boolean = true;
  filtros: IFiltros;
  key: string = 'data_pedido_sem_formato';
  order: string = 'desc';
  matMenuTimer: any;


  input$ = new Subject<string>();
  loading = false;
  termoEscrito = '';

  empresasGeral: IEmpresas[] = [];
  empresasSelecionadas: string[] = [];
  vendedorSelecionado: string = '';

  listaPedidos: IPedido[] = [];
  listaPedidosGeral: IPedido[] = [];
  listaVendedores: string[] = [];
  listaStatus: IStatus[] = [{id: 'T', descricao: 'Todos'},{id: 'I', descricao: 'Importado'},{id:'P',descricao:'Pendente'},{id: 'A',descricao: 'Aprovado'},{id: 'R',descricao: 'Reprovado'}]
  listaOrigem: IStatus[] = [{id: 'G', descricao: 'Todos'},{id: 'T', descricao: 'Terus'},{id: 'C', descricao: 'Cliente'}]

  idSelecionado: string = '';

  bufferSize = 50;

  faRotateRight = faRotateRight;

  constructor(
    private router: Router,
    private apiListaPedidos: ListaPedidosFornecedorService,
    private formatDate: FormatDate,
  ) { }

  ngOnInit(): void {
    this.ativaLoading = true;

    this.carregaFiltros();
  }

  async carregaFiltros(){

    this.filtros = JSON.parse(sessionStorage.getItem('filtros'));
    if(this.filtros){
      this.dataInicial = this.filtros.dataDe;
      this.dataFinal = this.filtros.dataAte;
      this.empresasSelecionadas = this.filtros.empresas;
      this.vendedorSelecionado = this.filtros.vendedor;
      this.statusSelecionado = this.filtros.status;
      this.order = this.filtros.order || 'desc';
      this.key = this.filtros.orderKey || 'data_pedido_sem_formato';
    }else{
      this.dataInicial = this.formatDate.formatDateForInput(new Date(), 30);
      this.dataFinal = this.formatDate.formatDateForInput(new Date(), 0);
      this.statusSelecionado = 'T';
    }

    const optionsPromise = this.apiListaPedidos.listaOpcoes().then(result =>{
      this.empresasGeral = result.empresas.sort((empresa1, empresa2) => {
        if (empresa1.id_loja_empresa > empresa2.id_loja_empresa) {
          return 1;
        }

        if (empresa1.id_loja_empresa < empresa2.id_loja_empresa) {
          return -1;
        }

        return 0;
      });

      if(this.empresasSelecionadas.length === 0){
        this.empresasSelecionadas = [this.empresasGeral[0].id_cliente+'_'+this.empresasGeral[0].id_empresa+'_'+this.empresasGeral[0].id_loja_empresa];
      }

      this.carregaDados();
    })

    const vendedoresPromise = this.apiListaPedidos.listaVendedores().then(result => {
      this.listaVendedores = result;
    })

    await Promise.all([optionsPromise, vendedoresPromise]);

    this.atualizaFiltros();
  }

  carregaDados(){
    this.ativaLoading = true;

    if(this.empresasSelecionadas){
      this.apiListaPedidos.ListasPedidos(this.empresasSelecionadas,this.vendedorSelecionado, this.dataInicial, this.dataFinal, this.statusSelecionado).then(result => {
        if(result.length<=0){
          this.listaPedidosGeral = [];
          this.listaPedidos = [];
          this.idSelecionado = '';
        }else{
          this.listaPedidosGeral = result;
          this.listaPedidos = result;
          this.idSelecionado = this.listaPedidos[0].id_pedido;
        }
        this.ativaLoading = false;

        if(this.origemSelecionado==="T"){
          this.listaPedidos = this.listaPedidosGeral.filter(e => e.numero_pedido_origem===" ");
        }else if(this.origemSelecionado==="C"){
          this.listaPedidos = this.listaPedidosGeral.filter(e => e.numero_pedido_origem.trim().length> 1);
        }else if(this.origemSelecionado==="G"){
          this.listaPedidos = this.listaPedidosGeral;
        }

        this.Toast.fire({
          icon: this.idSelecionado?'success':'warning',
          title: this.idSelecionado?'Pedidos Encontrados':'Nenhum pedido encontrado!',
        });
      });
    }else{
      this.Toast.fire({
        icon: 'warning',
        title: 'Selecione um fornecedor!'
      });
      this.ativaLoading = false;
    }

    this.atualizaFiltros();
  }

  atualizaPesquisa(){
    this.carregaDados();
  }

  selecionaLinha(id: string){
    this.idSelecionado = id;
  }

  visualizaPedido(){
    if(this.idSelecionado){
      this.router.navigate(['/pedidoSelecionado'], {
        queryParams: {
          idPedido: this.idSelecionado,
        }
      });
    }
  }

  aprovaPedido(){
    const pedido = this.listaPedidos.find(e => e.id_pedido == this.idSelecionado);

    if (!pedido) {
      this.Toast.fire({
        icon: 'warning',
        title: 'Pedido não encontrado!'
      });
      return;
    }

    if (this.pedidoDivergente(pedido.id_pedido)) {
      this.visualizaPedido()
      this.Toast.fire({
        icon: 'warning',
        title: 'Pedido divergente! Ajuste o preço de venda ou quantidade dos itens para aprovar.'
      });
      return;
    }

    if(pedido.status_pedido=='A' || pedido.status_pedido=='R'){
      this.Toast.fire({
        icon: 'warning',
        title: 'Pedido já foi '+(pedido.status_pedido=='A'?'Aprovado!':'Reprovado!')
      });
      return
    }

    this.ativaLoading = true;
    this.apiListaPedidos.setPedidos(this.idSelecionado,'A').then(result => {
      this.Toast.fire({
        icon: 'success',
        title: result
      });
      this.carregaDados();
    });
  }

  reprovaPedido(){
    let pedido = this.listaPedidos.filter(e => e.id_pedido == this.idSelecionado)[0];
    if(pedido.status_pedido=='A' || pedido.status_pedido=='R'){
      this.Toast.fire({
        icon: 'warning',
        title: 'Pedido já foi '+(pedido.status_pedido=='A'?'Aprovado!':'Reprovado!')
      });
      return
    }

    this.ativaLoading = true;
    this.apiListaPedidos.setPedidos(this.idSelecionado,'R').then(result => {
      this.Toast.fire({
        icon: 'success',
        title: result
      });
      this.carregaDados();
    });
  }

  filtraDados(id:string){
    if(id==="origem"){
      if(this.origemSelecionado==="T"){
        this.listaPedidos = this.listaPedidosGeral.filter(e => e.numero_pedido_origem===" ");
      }else if(this.origemSelecionado==="C"){
        this.listaPedidos = this.listaPedidosGeral.filter(e => e.numero_pedido_origem.trim().length> 1);
      }else if(this.origemSelecionado==="G"){
        this.listaPedidos = this.listaPedidosGeral;
      }
    }else{

      if(this.pesquisa){
        this.listaPedidos = this.listaPedidosGeral.filter(e => e.cnpj_fornecedor.indexOf(this.pesquisa.toUpperCase())>-1 || e.razao_social_fornecedor.indexOf(this.pesquisa.toUpperCase())>-1 || e.status_desc.indexOf(this.pesquisa.toUpperCase())>-1 || e.id_pedido.toUpperCase().indexOf(this.pesquisa.toUpperCase())>-1);
      }else{
        this.listaPedidos = this.listaPedidosGeral;
      }
    }

  }

  atualizaFiltros(){
    this.filtros = {
      dataDe: this.dataInicial,
      dataAte: this.dataFinal,
      empresas: this.empresasSelecionadas,
      vendedor: this.vendedorSelecionado,
      status: this.statusSelecionado,
      order: this.order,
      orderKey: this.key
    }

    sessionStorage.setItem('filtros', JSON.stringify(this.filtros));
  }

  ordernar(key){
    if(key==this.key)
      this.order = this.order=='asc'?'desc':'asc';
    else{
      this.order = 'asc';
    }
    this.key = key;
  }

  cardDoubleClick(id:string): void {
    clearTimeout(this.matMenuTimer);
    this.matMenuTimer = undefined;
    this.idSelecionado = id;

    this.router.navigate(['/pedidoSelecionado'], { queryParams: { idPedido: this.idSelecionado } } );
  }

  pedidoDivergente(idPedido: string): boolean {
    const pedido = this.listaPedidos.find((pedido) => pedido.id_pedido === idPedido);

    return pedido.itens.some((item) => this.precoDivergente(item) || this.quantidadeDivergente(item));
  }

  precoDivergente(item: IItens): boolean {
    return item.preco_tabela > item.preco_venda;
  }

  quantidadeDivergente(item: IItens): boolean {
    return item.quant_pedido % item.quant_ajst_multiplo !== 0;
  }
}
