<div class="container-fluid" style="height: 100vh; overflow: hidden;background-color: #C3C3C3;">
  <div class="row" style="height: 70px;">
      <app-header style="width: 100%;"></app-header>
  </div>
  <div class="row home">
    <div class="container-form">
      <div class="linha-header">
        <h3>Cadastro de Usuários</h3>
        <hr style="width: 100%;margin-top: 0.5rem;margin-bottom: 0.5rem;">
      </div>
      <div class="linha-campos">
        <form  id="formContatos" action="#" ngNativeValidate autocomplete="off">
          <div class="row">
            <div class="form-group col-md-2">
              <label for="inputCpf">CPF</label>
              <input [(ngModel)]="cpf" name="cpf" type="text" class="form-control" id="inputCpf" placeholder="CPF" required>
            </div>
            <div class="form-group col-md-4">
              <label for="inputNome">Nome</label>
              <input [(ngModel)]="nome" name="nome" type="text" class="form-control" id="inputNome" placeholder="Nome Contato" required>
            </div>
            <div class="form-group col-md-3">
              <label for="inputEmail">E-mail</label>
              <input [(ngModel)]="email" name="email" type="text" class="form-control" id="inputEmail" placeholder="E-mail" style="text-transform:lowercase;" required>
            </div>
            <div class="form-group col-md-2">
              <label for="inputTelefone">Telefone</label>
              <input [(ngModel)]="telefone" name="telefone" type="text" class="form-control" id="inputTelefone" placeholder="Telefone" required>
            </div>
            <div class="form-group col-md-1">
              <label for="inputStatus">Status</label>
              <select [(ngModel)]="status" name="status" class="form-select" id="inputStatus" required>
                <option *ngFor="let item of statusValores" [ngValue]="item.valor">{{ item.nome }}</option>
              </select>
            </div>
            <div class="form-group col-md-2">
              <label for="inputNivel">Nivel</label>
              <select [(ngModel)]="nivel" name="nivel" class="form-select" id="inputNivel" required>
                <option *ngFor="let item of nivelValores" [ngValue]="item.valor">{{ item.nome }}</option>
              </select>
            </div>
            <div class="form-group col-md-2">
              <label for="inputperm_ativ_fora">Permite Ativ. fora periodo</label>
              <select [(ngModel)]="perm_ativ_fora" name="perm_ativ_fora" class="form-select" id="inputperm_ativ_fora" required>
                <option *ngFor="let item of permAtivForaValores" [ngValue]="item.valor">{{ item.nome }}</option>
              </select>
            </div>
            <div class="form-group col-md-2">
              <label for="inputTipoEvidencia">Tipo Evidencia</label>
              <select [(ngModel)]="tipo_evidencias" name="tipo_evidencias" class="form-select" id="inputTipoEvidencia" required>
                <option *ngFor="let item of tipoEvidenciaValores" [ngValue]="item.valor">{{ item.nome }}</option>
              </select>
            </div>
            <div class="form-group col-md-2">
              <label for="inputNumAtiv">Nº de Atividades (0 ilimitado)</label>
              <input [(ngModel)]="num_atividades" name="num_atividades" type="text" class="form-control" id="inputNumAtiv" placeholder="Numero De Atividades" required>
            </div>
            <div class="form-group col-md-2">
              <label for="inputHoraInicio">Inicio Atividades</label>
              <input [(ngModel)]="hora_inicio" name="hora_inicio" type="time" class="form-control" id="inputTelefone" placeholder="Hora Inicio Atividades" required>
            </div>
            <div class="form-group col-md-2">
              <label for="inputHoraFim">Fim Atividades</label>
              <input [(ngModel)]="hora_fim" name="hora_fim" type="time" class="form-control" id="inputHoraFim" placeholder="Hora Fim Atividades" required>
            </div>
          </div>
          <div class="row-fluid" style="padding-left: 5px; display: flex; margin: 10px 0;background-color: #353232; border-radius: 5px;">
            <div class="form-group">
              <div class="form-group-button">
                <label for="inputGrupo">Empresas Vinculadas</label>
                <!-- <button type="button" class="btn btn-salvar btn-adicionar" (click)="addEmpresa()"><fa-icon [icon]="faPlus" ></fa-icon></button> -->
              </div>
              <div class="form-group cards-empresas">
                <div class="card-grupo-empresa" *ngFor="let empresa of usuariosEmpresas">
                  <div class="card-info">
                    <label class="cnpj-empresa">{{this.formatTextApi.formatCnpjCpf(empresa.cnpj) + ' - ' + empresa.nome_fantasia}}</label>
                    <label class="containerCheck">
                      <input type="checkbox" name="habilitaEmpresa" id="empresaCheck{{empresa.cnpj}}" [checked]="empresa.habilitado" (change)="marcaOpcao('EMPRESAS',empresa.cnpj)">
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="form-group-button">
                <label for="inputGrupo">Departamento Vinculados</label>
                <!-- <button type="button" class="btn btn-salvar btn-adicionar" (click)="addDepartamento()"><fa-icon [icon]="faPlus" ></fa-icon></button> -->
              </div>
              <div class="form-group cards-empresas">
                <div class="card-grupo-empresa" *ngFor="let departamentos of usuariosDepartamentos">
                  <div class="card-info">
                    <label class="cnpj-empresa">{{'CÓGIDO: '+departamentos.id_departamento + ' - ' + departamentos.descricao}}</label>
                    <label class="containerCheck">
                      <input type="checkbox" name="habilitaEmpresa" id="empresaCheck{{departamentos.id_departamento}}" [checked]="departamentos.habilitado" (change)="marcaOpcao('DEPARTAMENTO',departamentos.id_departamento)">
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="form-group-button">
                <label for="inputGrupo">Turnos Vinculados</label>
                <!-- <button type="button" class="btn btn-salvar btn-adicionar" (click)="addTurno()"><fa-icon [icon]="faPlus" ></fa-icon></button> -->
              </div>
              <div class="form-group cards-empresas">
                <div class="card-grupo-empresa" *ngFor="let turno of usuariosTurnos">
                  <div class="card-info">
                    <label class="cnpj-empresa">{{turno.descricao + '-' + turno.inicio_hora + '/' + turno.inicio_hora_intervalo + ' => ' + turno.fim_hora_intervalo + '/' + turno.fim_hora}}</label>
                    <label class="containerCheck">
                      <input type="checkbox" name="habilitaEmpresa" id="empresaCheck{{turno.id_turno}}" [checked]="turno.habilitado" (change)="marcaOpcao('TURNOS',turno.id_turno)">
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="form-group-button">
                <label for="inputGrupo">Funções Vinculadas</label>
                <!-- <button type="button" class="btn btn-salvar btn-adicionar" (click)="addFuncao()"><fa-icon [icon]="faPlus" ></fa-icon></button> -->
              </div>
              <div class="form-group cards-empresas">
                <div class="card-grupo-empresa" *ngFor="let funcoes of usuariosFuncoes">
                  <div class="card-info">
                    <label class="cnpj-empresa">{{'CÓGIDO: '+funcoes.id_funcao + ' - ' + funcoes.descricao}}</label>
                    <label class="containerCheck">
                      <input type="checkbox" name="habilitaEmpresa" id="empresaCheck{{funcoes.id_funcao}}" [checked]="funcoes.habilitado" (change)="marcaOpcao('FUNCOES',funcoes.id_funcao)">
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="form-group-button">
                <label for="inputGrupo">Fornecedores Vinculados</label>
              </div>
              <div class="form-group cards-empresas">
                <div class="card-grupo-empresa" *ngFor="let fornecedores of usuariosFornecedores">
                  <div class="card-info">
                    <label class="cnpj-empresa">{{this.formatTextApi.formatCnpjCpf(fornecedores.cnpj) + ' - ' + fornecedores.fantasia}}</label>
                    <label class="containerCheck">
                      <input type="checkbox" name="habilitaEmpresa" id="forneceCheck{{fornecedores.id_fornecedor}}" [checked]="fornecedores.habilitado" (change)="marcaOpcao('FORNECEDOR',fornecedores.id_fornecedor)">
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="controle-buttons">
          <button (click)="limparDados()" class="btn btn-limpar">Limpar</button>
          <button (click)="inserir()" class="btn btn-inserir">Inserir</button>
          <button id="buttonSalvar" (click)="salvar()" class="btn btn-salvar">Salvar</button>
        </div>
      </div>
      <hr style="width: 100%;margin-top: 0.5rem;margin-bottom: 0.5rem;">
      <div class="linha-dados">
        <table id='dataTable' datatable [dtOptions]=" dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered table-sm row-border hover">
          <thead>
            <tr>
              <th style="width: 100px;">CPF</th>
              <th style="width: 200px;">Nome Contato</th>
              <th style="width: 100px;">Setor</th>
              <th style="width: 75px;">Telefone</th>
              <th style="width: 75px;">Status</th>
              <th style="width: 75px;">Nivel</th>
              <th style="width: 60px;">Acoes</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let usuario of usuarios">
              <td>{{ this.formatTextApi.formatCnpjCpf(usuario.cpf) }}</td>
              <td>{{ usuario.nome }}</td>
              <td>{{ usuario.email }}</td>
              <td>{{ usuario.telefone }}</td>
              <td>{{ usuario.status=='A'?'ATIVO':'INATIVO' }}</td>
              <td>{{ usuario.nivel=='1'?'Master': usuario.nivel=='2'?'Coodenador':usuario.nivel=='3'?'Supervisor':usuario.nivel=='4'?'Operador':'Contato' }}</td>
              <td class="acoes-linha">
                <button type="button" class="btn btn-primary" (click)="editar(usuario.id_usuario)"><fa-icon [icon]="faEdit"></fa-icon></button>
                <button type="button" class="btn btn-secondary" (click)="modalNovaSenha(usuario.id_usuario)"><fa-icon [icon]="faLock"></fa-icon></button>
                <button type="button" class="btn btn-danger" (click)="modalPergunta(usuario.id_usuario)"><fa-icon [icon]="faTrash"></fa-icon></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- Modal de Excluir usuario -->
  <div class="modal" tabindex="-1" role="dialog" id=ModalExcluir>
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Excluir registro</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Deseja realmente excluir o registro?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="excluir(true)">Excluir</button>
          <button type="button" class="btn btn-danger" (click)="excluir(false)" data-dismiss="modal">Cancelar</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal de alterar senha -->
  <div class="modal" tabindex="-1" role="dialog" id="ModalAlterPass">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Nova Senha Gerada:</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <label for="inputEmpresa">{{this.new_pass}}</label>
          <button type="button" class="btn btn-copy" (click)="copyToClipboard()" alt="Copiar"><fa-icon [icon]="faCopy"></fa-icon></button>
        </div>
      </div>
    </div>
  </div>
      <!-- <app-footer></app-footer> -->
</div>