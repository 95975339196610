import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { FormatDate } from 'src/app/util/format-date';
import { AprovaPedidosCheckmeService } from './service/aprova-pedidos-checkme.service';
import Swal from 'sweetalert2';
import { IItens, IPedido } from '../../models/IPedidos';
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons';

declare let $: any;

type IDatasPesq = {
  dataDe: string;
  dataAte: string;
}

type ICondicoesPagamento = {
  cnpj_fornecedor_parceiro: string;
  codplpag: number;
  descricao: string;
  is_negociado: boolean;
  numdias: number;
  vlminpedido: string;
}

@Component({
  selector: 'app-aprova-pedidos-checkme',
  templateUrl: './aprova-pedidos-checkme.component.html',
  styleUrls: ['./aprova-pedidos-checkme.component.css']
})
export class AprovaPedidosCheckmeComponent implements OnInit {

  Toast: any = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })

  Modal: any = Swal.mixin({
    confirmButtonText: 'Ok',
  })

  idPedido: string;
  empresa: string;

  dataInicial: string;
  dataFinal: string;
  datasAtuais: IDatasPesq;
  ativaLoading: number = 0;
  key: string = 'data_pedido';
  order: string = 'desc';
  matMenuTimer: any;

  input$ = new Subject<string>();
  loading = false;
  termoEscrito = '';

  idSelecionado: string = '';
  idSelecionadoQuant: string = '';
  idSelecionadoPreco: string = '';
  quantidadeTotal: number = 0;
  valorPedidoTotal: number = 0;
  quantidadeItens: number = 0;
  valorPedidoTotalStr: string;

  condicoesPagamento: ICondicoesPagamento[] = [];

  pedido: IPedido = {} as IPedido;

  bufferSize = 50;

  faAnglesLeft = faAnglesLeft;

  constructor(
    private apiAprovaPed: AprovaPedidosCheckmeService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.route.queryParams
      .subscribe(params => {
        this.idPedido = params.idPedido;
        this.empresa = params.empresa;
      }
    );

    this.carregaDados();

    this.apiAprovaPed.ListaPlanosPagamento().then(result => {
      this.condicoesPagamento = result.sort((a, b) => {
        if (a.codplpag < b.codplpag) return -1;
        if (a.codplpag > b.codplpag) return 1;
        return 0;
      });
    }).finally(() => {
      this.ativaLoading--;
    });
  }

  montarStringDeErro(): string {
    switch (true) {
      case this.precoDivergente() && this.quantidadeDivergente():
        return 'Há itens com preço de venda menor que o preço de tabela e com quantidade divergente do múltiplo de venda.';
      case this.precoDivergente():
        return 'Há itens com preço de venda menor que o preço de tabela.';
      case this.quantidadeDivergente():
        return 'Há itens com quantidade divergente do múltiplo de venda.';
      default:
        return '';
    }
  }

  carregaDados(){
    this.ativaLoading += 2;
    this.quantidadeTotal = 0;
    this.valorPedidoTotal = 0;
    this.quantidadeItens = 0;

    this.apiAprovaPed.ListaItemPedido(this.idPedido).then(result => {
      this.pedido = result[0];
      this.pedido.itens.map(e => {
        this.quantidadeTotal += e.quant_pedido;
        this.valorPedidoTotal += e.total;
        this.quantidadeItens++;
      });
      this.valorPedidoTotalStr = this.valorPedidoTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

      if (this.pedidoDivergente()) {
        this.Modal.fire({
          icon: 'warning',
          title: this.montarStringDeErro()
        });
      }
    }).finally(() => {
      this.ativaLoading--;
    })
  }

  voltar(){
    this.router.navigate(['/listaPedidosFornecedor']);
  }

  selecionaLinha(id: string){
    this.idSelecionado = id;
  }

  aprovaPedido(){
    const pedido = this.pedido;

    if (!pedido) {
      return;
    }

    if (this.pedidoDivergente()) {
      this.Toast.fire({
        icon: 'warning',
        title: this.montarStringDeErro()
      });
      return;
    }

    if(pedido.status_pedido=='A' || pedido.status_pedido=='R'){
      this.Toast.fire({
        icon: 'warning',
        title: 'Pedido já foi '+(pedido.status_pedido=='A'?'Aprovado!':'Reprovado!')
      });
      return
    }

    this.ativaLoading++;
    this.apiAprovaPed.setPedidosStatus(pedido.id_pedido,'A').then(result => {
      this.Toast.fire({
        icon: 'success',
        title: result
      }).then( r => {this.voltar();});
    });
  }

  reprovaPedido(){
    let pedido = this.pedido;
    if(pedido.status_pedido=='A' || pedido.status_pedido=='R'){
      this.Toast.fire({
        icon: 'warning',
        title: 'Pedido já foi '+(pedido.status_pedido=='A'?'Aprovado!':'Reprovado!')
      });
      return
    }

    this.ativaLoading++;
    this.apiAprovaPed.setPedidosStatus(pedido.id_pedido,'R').then(result => {
      this.Toast.fire({
        icon: 'success',
        title: result
      }).then( r => {this.voltar();});
    });
  }

  ordernar(key){
    if(key==this.key)
      this.order = this.order=='asc'?'desc':'asc';
    else{
      this.order = 'asc';
    }
    this.key = key;
  }

  cardDoubleClick(id:string,tipo): void {
    clearTimeout(this.matMenuTimer);
    this.matMenuTimer = undefined;

    if(tipo=='quant'){
      this.idSelecionadoPreco = '';
      this.idSelecionadoQuant = id;
      setTimeout( () => {$('#input_'+this.idSelecionadoQuant).focus().select();}, 400)
    }

    if(tipo=='preco'){
      this.idSelecionadoPreco = id;
      this.idSelecionadoQuant = '';
      setTimeout( () => {$('#input_'+this.idSelecionadoPreco).focus().select();}, 400)
    }
  }

  clickedMe() {
    if (!this.matMenuTimer) return;
  }

  onKeydown(event,tipo) {
    var novoValor = event.target.value;

    if(tipo=='cond'){
      this.pedido.prazo_pagamento = novoValor;
      return;
    }

    if(tipo=='escape'){
      this.idSelecionadoPreco = '';
      this.idSelecionadoQuant = '';
    }

    if(tipo=='quant'){
      this.pedido.itens.filter(e => 'quant_'+e.id_pedido+e.codigo_produto_cliente == this.idSelecionadoQuant)[0].quant_pedido = parseInt(novoValor);
      this.idSelecionadoQuant = this.pedido.itens.filter(e => tipo+'_'+e.id_pedido+e.codigo_produto_cliente == this.idSelecionadoQuant)[0].id_pedido;
    }

    if(tipo=='preco'){
      this.pedido.itens.filter(e => 'preco_'+e.id_pedido+e.codigo_produto_cliente == this.idSelecionadoPreco)[0].preco_venda_confirmada = parseFloat(novoValor);
      this.idSelecionadoPreco = this.pedido.itens.filter(e => tipo+'_'+e.id_pedido+e.codigo_produto_cliente == this.idSelecionadoPreco)[0].id_pedido;
    }

    this.quantidadeTotal = 0;
    this.valorPedidoTotal = 0;
    this.pedido.itens.map(e => {

      this.quantidadeTotal += e.quant_pedido;
      this.valorPedidoTotal += ((e.preco_venda_confirmada?e.preco_venda_confirmada:e.preco_venda)*(e.quant_pedido));

    });

    this.valorPedidoTotalStr = this.valorPedidoTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

  }

  salvarPedido(){
    this.ativaLoading++;
    this.apiAprovaPed.setPedidos(this.pedido.id_pedido, this.pedido, 'S').then(result => {
      this.Toast.fire({
        icon: 'success',
        title: 'Pedido Salvo'
      });

      this.carregaDados();
    });
  }

  pedidoDivergente(): boolean {
    return this.precoDivergente() || this.quantidadeDivergente();
  }

  itemPrecoDivergente(item: IItens): boolean {
    const condSelecionada = this.condicoesPagamento.find(e => String(e.codplpag) === this.pedido.prazo_pagamento);

    if (condSelecionada.is_negociado) {
      return false
    }

    return item.preco_tabela > item.preco_venda;
  }

  itemQuantidadeDivergente(item: IItens): boolean {
    return item.quant_pedido % item.quant_ajst_multiplo !== 0;
  }

  precoDivergente(): boolean {
    if (!this.pedido.itens) {
      return false;
    }

    return this.pedido.itens.some((item) => this.itemPrecoDivergente(item));
  }


  quantidadeDivergente(): boolean {
    if (!this.pedido.itens) {
      return false;
    }

    return this.pedido.itens.some((item) => this.itemQuantidadeDivergente(item));
  }
}
